import { useState, createContext } from "react";
import FetchService from "./FetchService";

const AccountContext = createContext();

function AccountService({ children }) {
  const [authToken, setAuthToken] = useState(false);
  const [isSuperadmin, setIsSuperadmin] = useState(false);
  const [hotelName, setHotelName] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [previousDate, setPreviousDate] = useState();
  const [displayDate, setDisplayDate] = useState();
  const [calendarMode, setCalendarMode] = useState("decade");

  const handleAuthToken = (authToken) => {
    setAuthToken(authToken);
    localStorage.setItem("localAuthorization", authToken);
  };

  const login = async (email, password) => {
    return await new Promise((resolve, reject) => {
      FetchService.post("/api/auth/login", {
        email: email,
        password: password,
      })
        .then((res) => {
          handleAuthToken(res.data.access_token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const refreshToken = async (authToken) => {
    return await new Promise((resolve, reject) => {
      FetchService.post("/api/auth/token/refresh", { access_token: authToken })
        .then((res) => {
          handleAuthToken(res.data.access_token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const whoAmI = async () => {
    return await new Promise((resolve, reject) => {
      FetchService.get("/api/users/me")
        .then((res) => {
          setIsSuperadmin(res.data.is_superadmin);
          setHotelName(res.data.hotel_name);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const logout = () => {
    setAuthToken(false);
    setHotelName("");
    setIsSuperadmin("");
    setSelectedDate("");
    setPreviousDate("");
    setDisplayDate("");
    localStorage.removeItem("localAuthorization");
  };

  return (
    <AccountContext.Provider
      value={{
        authToken,
        setAuthToken,
        isSuperadmin,
        hotelName,
        login,
        refreshToken,
        whoAmI,
        logout,
        selectedDate,
        setSelectedDate,
        displayDate,
        setDisplayDate,
        calendarMode,
        setCalendarMode,
        previousDate,
        setPreviousDate,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export { AccountService, AccountContext };
