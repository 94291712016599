import LayoutFullSideNav from "../../components/Layout/LayoutFullSideNav";
import LayoutSection from "../../components/Layout/LayoutSection";
import SmallTitleGraphic from "../../components/Title/SmallTitleGraphic";
import RiqChartsLine from "../../components/RiqCharts/RiqChartsLine";
import KpiBlock from "../../components/Kpi/KpiBlock";
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";

const Compset = () => {
  const title = "Compset";
  const menu = ["MPI", "ARI", "RGI"];

  return (
    <LayoutFullSideNav title={title} menu={menu}>
      <WorkInProgress
        logo={process.env.PUBLIC_URL + "/imgs/str.png"}
        section={title}
        provider={"STR"}
      />
      <LayoutSection>
        <SmallTitleGraphic
          content={menu[0]}
          extraContent={"(Market Penetration Index)"}
          shapeType={1}
        />
        <RiqChartsLine
          data={[
            { month: "JAN", a: null, b: null },
            { month: "FEB", a: null, b: null },
            { month: "MAR", a: null, b: null },
            { month: "APR", a: null, b: null },
            { month: "MAY", a: null, b: null },
            { month: "JUN", a: null, b: null },
            { month: "JUL", a: null, b: null },
            { month: "AUG", a: null, b: null },
            { month: "SEP", a: null, b: null },
            { month: "OCT", a: null, b: null },
            { month: "NOV", a: null, b: null },
            { month: "DEC", a: null, b: null },
          ]}
          unavailableDataMessage={false}
        />
        {/* <KpiBlock
          valueLeft={
            <div className="d-flex justify-content-start align-items-center">
              <div>+11.9%</div>
              <div>
                <i
                  className="d-none d-xl-inline bi bi-chevron-double-up ms-2"
                  style={{ fontSize: "60px" }}
                ></i>
              </div>
            </div>
          }
          textLeft="VARIANCE"
          valueRight={"124.9%"}
          textRight="MAX MPI"
        /> */}
      </LayoutSection>

      <LayoutSection>
        <SmallTitleGraphic
          content={menu[1]}
          extraContent={"(Average Rate Index)"}
          shapeType={2}
        />
        <RiqChartsLine
          data={[
            { month: "JAN", a: null, b: null },
            { month: "FEB", a: null, b: null },
            { month: "MAR", a: null, b: null },
            { month: "APR", a: null, b: null },
            { month: "MAY", a: null, b: null },
            { month: "JUN", a: null, b: null },
            { month: "JUL", a: null, b: null },
            { month: "AUG", a: null, b: null },
            { month: "SEP", a: null, b: null },
            { month: "OCT", a: null, b: null },
            { month: "NOV", a: null, b: null },
            { month: "DEC", a: null, b: null },
          ]}
          unavailableDataMessage={false}
        />
        {/* <KpiBlock
          valueLeft={"109.3%"}
          textLeft="MEDIA ARI"
          valueRight={"142.5%"}
          textRight="MAX ARI"
        /> */}
      </LayoutSection>

      <LayoutSection>
        <SmallTitleGraphic
          content={menu[2]}
          extraContent={"(Revenue Generating Index)"}
          shapeType={3}
        />
        <RiqChartsLine
          data={[
            { month: "JAN", a: null, b: null },
            { month: "FEB", a: null, b: null },
            { month: "MAR", a: null, b: null },
            { month: "APR", a: null, b: null },
            { month: "MAY", a: null, b: null },
            { month: "JUN", a: null, b: null },
            { month: "JUL", a: null, b: null },
            { month: "AUG", a: null, b: null },
            { month: "SEP", a: null, b: null },
            { month: "OCT", a: null, b: null },
            { month: "NOV", a: null, b: null },
            { month: "DEC", a: null, b: null },
          ]}
          unavailableDataMessage={false}
        />
        {/* <KpiBlock
          valueLeft={"111.6%"}
          textLeft="MEDIA RGI"
          valueRight={"141.1%"}
          textRight="MAX RGI"
        /> */}
      </LayoutSection>
    </LayoutFullSideNav>
  );
};

export default Compset;
