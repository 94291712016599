import "./Title.css";

const SmallTitleGraphic = ({
  content,
  extraContent,
  styleClass = "mb-0",
  shapeType = 0,
}) => {
  return (
    <div id={content} className="small-title-container mb-2">
      <h3 className={`small-title-text ${styleClass}`}>
        <b>
          {content}
          {extraContent && ` ${extraContent}`}
        </b>
      </h3>

      {shapeType === 1 ? (
        <>
          <div className="yellow-shape a"></div>
          <div className="yellow-shape b"></div>
          <div className="yellow-shape c"></div>
        </>
      ) : shapeType === 2 ? (
        <>
          <div className="yellow-shape d"></div>
        </>
      ) : shapeType === 3 ? (
        <>
          <div className="yellow-shape e"></div>
          <div className="yellow-shape f"></div>
        </>
      ) : shapeType === 4 ? (
        <>
          <div className="yellow-shape g"></div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SmallTitleGraphic;
