import LayoutFullSideNav from "../../components/Layout/LayoutFullSideNav";
import LayoutSection from "../../components/Layout/LayoutSection";
import SmallTitleGraphic from "../../components/Title/SmallTitleGraphic";
import RiqChartsLine from "../../components/RiqCharts/RiqChartsLine";
import KpiBlock from "../../components/Kpi/KpiBlock";
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";

const Market = () => {
  const title = "Mercato";
  const menu = ["MARKET DEMAND"];

  return (
    <LayoutFullSideNav title={title} menu={menu}>
      {/* TODO: da togliere se esistono questi dati */}
      <WorkInProgress
        logo={process.env.PUBLIC_URL + "/imgs/lighthouse.jpg"}
        section={title}
        provider={"Lighthouse (formerly OTA Insight)"}
      />
      <LayoutSection>
        <SmallTitleGraphic content={menu[0]} shapeType={1} />
        <RiqChartsLine
          data={[
            { month: "JAN", 2023: 42.3, 2022: 30.6 },
            { month: "FEB", 2023: 38.2, 2022: 30.4 },
            { month: "MAR", 2023: 39.3, 2022: 31.2 },
            { month: "APR", 2023: 28.3, 2022: 22.4 },
            { month: "MAY", 2023: 35.4, 2022: 28.0 },
            { month: "JUN", 2023: 38.7, 2022: 30.4 },
            { month: "JUL", 2023: 15.1, 2022: 14.0 },
            { month: "AUG", 2023: 21.5, 2022: 16.8 },
            { month: "SEP", 2023: 18.5, 2022: 14.4 },
            { month: "OCT", 2023: 32.3, 2022: 25.6 },
            { month: "NOV", 2023: 36.2, 2022: 28.8 },
            { month: "DEC", 2023: 15.2, 2022: 12.0 },
          ]}
        />
        <KpiBlock
          valueLeft={"30.1%"}
          textLeft="MEDIA MARKET DEMAND"
          kpiRight={false}
        />
      </LayoutSection>
    </LayoutFullSideNav>
  );
};

export default Market;
