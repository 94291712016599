import { Tooltip } from "react-tooltip";
import "./TooltipWrapper.css";

const TooltipWrapper = ({
  id = `tooltip`,
  content = "Info",
  place = "right",
  children,
}) => {
  return (
    <>
      <div
        data-tooltip-id={id}
        data-tooltip-html={content}
        data-tooltip-place={place}
        data-tooltip-delay-hide={150}
        data-tooltip-variant="light"
      >
        {children}
      </div>
      <Tooltip id={id} noArrow className="tooltip-container" />
    </>
  );
};

export default TooltipWrapper;
