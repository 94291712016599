import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../services/AccountService";
import Input from "../../components/Input/Input";
import InputPassword from "../../components/Input/InputPassword";
import Loadable from "../../components/Loader/Loadable";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";

const DefaultLogin = ({
  loginEmail,
  loginPassword,
  setLoginEmail,
  setLoginPassword,
  setIsFirstLogin,
  setIsResetPassword,
}) => {
  const { login } = useContext(AccountContext);

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [alert, setAlert] = useState({});
  const btnLogin = useRef();
  const navigate = useNavigate();

  const handleLogin = (email = loginEmail, password = loginPassword) => {
    setIsLoadingButton(true);

    login(email, password)
      .then((res) => {
        if (res.data.first_login) {
          setIsFirstLogin(true);
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        /* setAlert({ message: formatErrorForAlert(err), messageType: "error" }); */
        setAlert({
          message: "Wrong email or password",
          messageType: "error",
        });
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  // listener for enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        btnLogin.current.click();
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <Alert
        styleClass={""}
        alertText={alert && alert.message}
        styleColor={alert && alert.messageType}
        onClick={setAlert}
        paddingLeft={0}
      />

      <div className="text-center container-login">
        <img
          src={process.env.PUBLIC_URL + "/imgs/logo.svg"}
          className="logo-login mb-4"
          alt="roomsiq logo"
        />

        <Input
          styleClass="mb-2"
          type="email"
          id="loginEmail"
          placeholder="name@example.com"
          value={loginEmail}
          onChange={(e) => {
            setLoginEmail(e.target.value);
          }}
          label={"Email"}
        />

        <InputPassword
          styleClass="mb-1"
          type="password"
          id="loginPassword"
          placeholder="password"
          value={loginPassword}
          onChange={(e) => {
            setLoginPassword(e.target.value);
          }}
          label="Password"
        />

        <div className="text-end mb-4">
          <div
            className="cursor-pointer forgot-pw"
            onClick={() => {
              setIsResetPassword(true);
            }}
          >
            Hai dimenticato la tua password?
          </div>
        </div>
      </div>

      <div className="container-btn">
        <Loadable isLoading={isLoadingButton}>
          <Button
            text="Login"
            onClick={() => {
              handleLogin();
            }}
            innerRef={btnLogin}
          />
        </Loadable>
      </div>
    </>
  );
};

export default DefaultLogin;
