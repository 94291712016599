const WorkInProgress = ({ logo, section, provider }) => {
  return (
    <>
      {/* <div className="d-flex justify-content-center align-items-center h-100 text-center pb-4">
      <div style={{ fontSize: "40px" }}>🚧</div>

      <div className="mx-3">
        <div style={{ fontSize: "40px" }}>
          <strong>Coming soon</strong>
        </div>
        <div>Questa pagina è in costruzione</div>
      </div>

      <div style={{ fontSize: "40px" }}>🚧</div>
    </div> */}

      <div className="d-flex justify-content-center align-items-center h-100 text-center pb-4">
        <img src={logo} height={"40px"} className="me-3" />
        <div className="text-start">
          <div style={{ fontSize: "20px" }}>
            <strong>Vuoi attivare la sezione {section}?</strong>
          </div>
          <div>Contattaci per abilitare i dati {provider}</div>
        </div>
      </div>
    </>
  );
};

export default WorkInProgress;
