import "./Separator.css";

const Separator = () => {
  return (
    <div className="separator-container my-4">
      <div className="separator"></div>
    </div>
  );
};

export default Separator;
