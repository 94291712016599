import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import FetchService from "./services/FetchService";
import { AccountContext } from "./services/AccountService";
import { formatErrorForAlert } from "./services/UtilsService";
import AddUser from "./pages/AddUser/AddUser";
import Login from "./pages/Login/Login";
import NotFound from "./pages/NotFound/NotFound";
import My from "./pages/My/My";
import Compset from "./pages/Compset/Compset";
import Market from "./pages/Market/Market";
import RateShopper from "./pages/RateShopper/RateShopper";
import "./styles/App.css";

function App() {
  const [checkToken, setCheckToken] = useState(false);
  const { authToken, isSuperadmin, refreshToken, whoAmI } =
    useContext(AccountContext);

  useEffect(() => {
    // config all requests with auth token
    FetchService.interceptors.request.clear();

    let tempToken;
    if (authToken) {
      tempToken = authToken;
    } else {
      tempToken = localStorage.getItem("localAuthorization");
    }

    if (FetchService.interceptors.request.handlers.length === 0) {
      FetchService.interceptors.request.use((config) => {
        config.headers.Authorization = "Bearer " + tempToken;
        return config;
      });
    }

    if (tempToken) {
      whoAmI()
        .then(() => {
          setCheckToken(true);
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            refreshToken(tempToken);
          } else {
            console.log(formatErrorForAlert(err));
            setCheckToken(false);
          }
        });
    } else {
      setCheckToken(false);
    }
  }, [authToken]);

  return checkToken ? (
    <main>
      <Routes>
        <Route path="/" element={isSuperadmin ? <AddUser /> : <My />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route path="/my" element={<My />} />
        <Route path="/compset" element={<Compset />} />
        <Route path="/market" element={<Market />} />
        <Route path="/rate-shopper" element={<RateShopper />} />
        <Route path="/login" element={<Login />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </main>
  ) : (
    <Login />
  );
}

export default App;
