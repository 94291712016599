import { useState } from "react";
import DefaultLogin from "./DefaultLogin";
import FirstLogin from "./FirstLogin";
import ResetPassword from "./ResetPassword";
import "./Login.css";

const Login = () => {
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  return (
    <div className="page-login d-flex flex-column justify-content-center align-items-center">
      {isFirstLogin ? (
        <FirstLogin
          setIsFirstLogin={setIsFirstLogin}
          loginEmail={loginEmail}
          loginPassword={loginPassword}
        />
      ) : isResetPassword ? (
        <ResetPassword setIsResetPassword={setIsResetPassword} />
      ) : (
        <DefaultLogin
          loginEmail={loginEmail}
          setLoginEmail={setLoginEmail}
          loginPassword={loginPassword}
          setLoginPassword={setLoginPassword}
          setIsFirstLogin={setIsFirstLogin}
          setIsResetPassword={setIsResetPassword}
        />
      )}
    </div>
  );
};

export default Login;
