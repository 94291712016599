import { useEffect, useContext, useState } from "react";
import { AccountContext } from "../../services/AccountService";
import {
  assembleBody,
  formatErrorForAlert,
  assembleDataForChart,
  formatDateSelected,
  formatNumberFull,
  formatNumberFullNoDecimal,
} from "../../services/UtilsService";
import FetchService from "../../services/FetchService";
import LayoutFullSideNav from "../../components/Layout/LayoutFullSideNav";
import LayoutSection from "../../components/Layout/LayoutSection";
import SmallTitleGraphic from "../../components/Title/SmallTitleGraphic";
import RiqChartsLine from "../../components/RiqCharts/RiqChartsLine";
import KpiBlock from "../../components/Kpi/KpiBlock";
import Alert from "../../components/Alert/Alert";
import Refreshing from "../../components/Refreshing/Refreshing";

const My = () => {
  const menu = ["OCCUPANCY", "REVENUE", "ADR", "REVPAR"];
  const { hotelName, selectedDate, previousDate, calendarMode } =
    useContext(AccountContext);
  const [alert, setAlert] = useState();

  const [dataOccupancy, setDataOccupancy] = useState([]);
  const [isLoadingOccupancy, setIsLoadingOccupancy] = useState(false);
  const [dataOccupancyAvg, setDataOccupancyAvg] = useState();
  const [isLoadingOccupancyAvg, setIsLoadingOccupancyAvg] = useState(false);
  const [dataOccupancyMax, setDataOccupancyMax] = useState();
  const [isLoadingOccupancyMax, setIsLoadingOccupancyMax] = useState(false);

  const [dataRevenue, setDataRevenue] = useState([]);
  const [isLoadingRevenue, setIsLoadingRevenue] = useState(false);
  const [dataRevenueAvg, setDataRevenueAvg] = useState();
  const [isLoadingRevenueAvg, setIsLoadingRevenueAvg] = useState(false);
  /* const [dataRevenueMax, setDataRevenueMax] = useState();
  const [isLoadingRevenueMax, setIsLoadingRevenueMax] = useState(false); */
  const [dataRevenueTot, setDataRevenueTot] = useState();

  const [dataAdr, setDataAdr] = useState([]);
  const [isLoadingAdr, setIsLoadingAdr] = useState(false);
  const [dataAdrAvg, setDataAdrAvg] = useState();
  const [isLoadingAdrAvg, setIsLoadingAdrAvg] = useState(false);
  const [dataAdrMax, setDataAdrMax] = useState();
  const [isLoadingAdrMax, setIsLoadingAdrMax] = useState(false);

  const [dataRevpar, setDataRevpar] = useState([]);
  const [isLoadingRevpar, setIsLoadingRevpar] = useState(false);
  const [dataRevparAvg, setDataRevparAvg] = useState();
  const [isLoadingRevparAvg, setIsLoadingRevparAvg] = useState(false);
  const [dataRevparMax, setDataRevparMax] = useState();
  const [isLoadingRevparMax, setIsLoadingRevparMax] = useState(false);

  const getFriendlyName = (date) => {
    return formatDateSelected(calendarMode, date, true);
  };

  useEffect(() => {
    if (selectedDate) {
      let body = assembleBody(hotelName, selectedDate, calendarMode);
      let bodyPrev = assembleBody(hotelName, previousDate, calendarMode);

      getOccupancy(body, bodyPrev);
      getOccupancyAvg(body);
      getOccupancyMax(body);

      getRevenue(body, bodyPrev);
      getRevenueAvg(body);
      //getRevenueMax(body);

      getAdr(body, bodyPrev);
      getAdrAvg(body);
      getAdrMax(body);

      getRevpar(body, bodyPrev);
      getRevparAvg(body);
      getRevparMax(body);
    }
  }, [selectedDate, calendarMode]);

  // calculate total revenue for selected period
  useEffect(() => {
    if (dataRevenue) {
      getRevenueTot();
    }
  }, [dataRevenue]);

  // ==================== Occupancy ====================
  const getOccupancy = (body, bodyPrev) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/occupancy`;
    setIsLoadingOccupancy(true);

    FetchService.post(api, body)
      .then((resCurrent) => {
        FetchService.post(api, bodyPrev)
          .then((resPrev) => {
            setDataOccupancy(
              assembleDataForChart(
                calendarMode,
                resCurrent.data,
                resPrev.data,
                calendarMode === "decade" ? "average_occupancy" : "occupancy",
                hotelName
              )
            );
          })
          .catch((err) => {
            setAlert({
              message: formatErrorForAlert(err),
              messageType: "error",
            });
          })
          .finally(() => setIsLoadingOccupancy(false));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getOccupancyAvg = (body) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/occupancy/avg`;
    setIsLoadingOccupancyAvg(true);

    FetchService.post(api, body)
      .then((res) => {
        setDataOccupancyAvg(
          formatNumberFull(res.data.average_occupancy || res.data.avg)
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingOccupancyAvg(false));
  };

  const getOccupancyMax = (body) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/occupancy/max`;
    setIsLoadingOccupancyMax(true);

    FetchService.post(api, body)
      .then((res) => {
        setDataOccupancyMax(
          formatNumberFull(res.data.max_occupancy || res.data.max)
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingOccupancyMax(false));
  };

  // ==================== Revenue ====================
  const getRevenue = (body, bodyPrev) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/revenue`;
    setIsLoadingRevenue(true);

    FetchService.post(api, body)
      .then((resCurrent) => {
        FetchService.post(api, bodyPrev)
          .then((resPrev) => {
            setDataRevenue(
              assembleDataForChart(
                calendarMode,
                resCurrent.data,
                resPrev.data,
                "revenue",
                hotelName
              )
            );
          })
          .catch((err) => {
            setAlert({
              message: formatErrorForAlert(err),
              messageType: "error",
            });
          })
          .finally(() => setIsLoadingRevenue(false));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getRevenueAvg = (body) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/revenue/avg`;
    setIsLoadingRevenueAvg(true);

    FetchService.post(api, body)
      .then((res) => {
        setDataRevenueAvg(
          formatNumberFullNoDecimal(res.data.average_revenue || res.data.avg)
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingRevenueAvg(false));
  };

  /* const getRevenueMax = () => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/revenue/max`;
    setIsLoadingRevenueMax(true);

    FetchService.post(api, body)
      .then((res) => {
        setDataRevenueMax(
          formatNumberFullNoDecimal(res.data.max_revenue || res.data.max)
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingRevenueMax(false));
  }; */

  const getRevenueTot = () => {
    let tot = 0;
    dataRevenue.forEach((el) => {
      tot += el.black;
    });
    setDataRevenueTot(formatNumberFullNoDecimal(tot));
  };

  // ==================== ADR ====================
  const getAdr = (body, bodyPrev) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/adr`;
    setIsLoadingAdr(true);

    FetchService.post(api, body)
      .then((resCurrent) => {
        FetchService.post(api, bodyPrev)
          .then((resPrev) => {
            setDataAdr(
              assembleDataForChart(
                calendarMode,
                resCurrent.data,
                resPrev.data,
                calendarMode === "decade" ? "average_adr" : "adr",
                hotelName
              )
            );
          })
          .catch((err) => {
            setAlert({
              message: formatErrorForAlert(err),
              messageType: "error",
            });
          })
          .finally(() => setIsLoadingAdr(false));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getAdrAvg = (body) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/adr/avg`;
    setIsLoadingAdrAvg(true);

    FetchService.post(api, body)
      .then((res) => {
        setDataAdrAvg(formatNumberFull(res.data.average_adr || res.data.avg));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAdrAvg(false));
  };

  const getAdrMax = (body) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/adr/max`;
    setIsLoadingAdrMax(true);

    FetchService.post(api, body)
      .then((res) => {
        setDataAdrMax(formatNumberFull(res.data.max_adr || res.data.max));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAdrMax(false));
  };

  // ==================== REVPAR ====================
  const getRevpar = (body, bodyPrev) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/revpar`;
    setIsLoadingRevpar(true);

    FetchService.post(api, body)
      .then((resCurrent) => {
        FetchService.post(api, bodyPrev)
          .then((resPrev) => {
            setDataRevpar(
              assembleDataForChart(
                calendarMode,
                resCurrent.data,
                resPrev.data,
                calendarMode === "decade" ? "average_revpar" : "revpar",
                hotelName
              )
            );
          })
          .catch((err) => {
            setAlert({
              message: formatErrorForAlert(err),
              messageType: "error",
            });
          })
          .finally(() => setIsLoadingRevpar(false));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getRevparAvg = (body) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/revpar/avg`;
    setIsLoadingRevparAvg(true);

    FetchService.post(api, body)
      .then((res) => {
        setDataRevparAvg(
          formatNumberFull(res.data.average_revpar || res.data.avg)
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingRevparAvg(false));
  };

  const getRevparMax = (body) => {
    let api = `/api/dashboard/pms/${
      calendarMode === "decade" ? "yearly" : "monthly"
    }/revpar/max`;
    setIsLoadingRevparMax(true);

    FetchService.post(api, body)
      .then((res) => {
        setDataRevparMax(formatNumberFull(res.data.max_revpar || res.data.max));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingRevparMax(false));
  };

  return (
    <LayoutFullSideNav title={"I miei indicatori"} menu={menu}>
      <Refreshing
        conditions={[
          isLoadingOccupancy,
          isLoadingOccupancyAvg,
          isLoadingOccupancyMax,
          isLoadingRevenue,
          isLoadingRevenueAvg,
          //isLoadingRevenueMax,
          isLoadingAdr,
          isLoadingAdrAvg,
          isLoadingAdrMax,
          isLoadingRevpar,
          isLoadingRevparAvg,
          isLoadingRevparMax,
        ]}
      />
      <Alert
        styleClass={""}
        alertText={alert && alert.message}
        styleColor={alert && alert.messageType}
        onClick={setAlert}
      />

      {/* // ==================== Occupancy ==================== */}
      <LayoutSection>
        <SmallTitleGraphic content={menu[0]} shapeType={1} />
        <RiqChartsLine
          data={dataOccupancy}
          dataGuide={{
            x: "dt",
            linesData: [
              {
                dataName: "black",
                friendlyName: getFriendlyName(selectedDate),
              },
              {
                dataName: "yellow",
                friendlyName: getFriendlyName(previousDate),
              },
            ],
          }}
          isLoading={isLoadingOccupancy}
        />
        <KpiBlock
          valueLeft={`${dataOccupancyAvg}%`}
          textLeft="MEDIA OCCUPANCY"
          valueRight={`${dataOccupancyMax}%`}
          textRight="MAX OCCUPANCY"
        />
      </LayoutSection>

      {/* // ==================== Revenue ==================== */}
      <LayoutSection>
        <SmallTitleGraphic content={menu[1]} shapeType={2} />
        <RiqChartsLine
          data={dataRevenue}
          dataGuide={{
            x: "dt",
            linesData: [
              {
                dataName: "black",
                friendlyName: getFriendlyName(selectedDate),
              },
              {
                dataName: "yellow",
                friendlyName: getFriendlyName(previousDate),
              },
            ],
          }}
          isLoading={isLoadingRevenue}
          noDecimals={true}
        />
        <KpiBlock
          valueLeft={`${dataRevenueAvg}€`}
          textLeft="MEDIA REVENUE"
          valueRight={`${dataRevenueTot}€`}
          textRight={`TOT REVENUE ${getFriendlyName(selectedDate)}`}
        />
      </LayoutSection>

      {/* // ==================== ADR ==================== */}
      <LayoutSection>
        <SmallTitleGraphic content={menu[2]} shapeType={3} />
        <RiqChartsLine
          data={dataAdr}
          dataGuide={{
            x: "dt",
            linesData: [
              {
                dataName: "black",
                friendlyName: getFriendlyName(selectedDate),
              },
              {
                dataName: "yellow",
                friendlyName: getFriendlyName(previousDate),
              },
            ],
          }}
          isLoading={isLoadingAdr}
        />
        <KpiBlock
          valueLeft={dataAdrAvg}
          textLeft="MEDIA ADR"
          valueRight={dataAdrMax}
          textRight="MAX ADR"
        />
      </LayoutSection>

      {/* // ==================== REVPAR ==================== */}
      <LayoutSection>
        <SmallTitleGraphic content={menu[3]} shapeType={4} />
        <RiqChartsLine
          data={dataRevpar}
          dataGuide={{
            x: "dt",
            linesData: [
              {
                dataName: "black",
                friendlyName: getFriendlyName(selectedDate),
              },
              {
                dataName: "yellow",
                friendlyName: getFriendlyName(previousDate),
              },
            ],
          }}
          isLoading={isLoadingRevpar}
        />
        <KpiBlock
          valueLeft={dataRevparAvg}
          textLeft="MEDIA REVPAR"
          valueRight={dataRevparMax}
          textRight="MAX REVPAR"
        />
      </LayoutSection>
    </LayoutFullSideNav>
  );
};

export default My;
