import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import {
  formatNumberFull,
  formatNumberFullNoDecimal,
} from "../../services/UtilsService";
import { _variablesChart } from "../../styles/_variables";
import "./RiqCharts.css";

const RiqChartsLine = ({
  data,
  dataGuide = {
    x: "month",
    linesData: [
      { dataName: "", friendlyName: "" },
      { dataName: "", friendlyName: "" },
    ],
  },
  isLoading = false,
  noDecimals = false,
  unavailableDataMessage = true,
}) => {
  const COLORS = Object.values(_variablesChart);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip-container p-2">
          <div className="mb-1">{label}</div>
          {payload.map((p, index) => {
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                key={p.value + index}
              >
                <div
                  className="circle me-1"
                  style={{ backgroundColor: p.color }}
                ></div>
                <div>
                  {p.name}:{" "}
                  {noDecimals
                    ? formatNumberFullNoDecimal(p.value)
                    : formatNumberFull(p.value)}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return unavailableDataMessage && data.length === 0 ? (
    <NoDataAvailable isLoading={isLoading} />
  ) : (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart width="100%" height="100%" data={data}>
        <CartesianGrid strokeDasharray="2 10" />
        <XAxis dataKey={dataGuide.x} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" height={40} />
        {dataGuide.linesData.map((line, index) => {
          return (
            <Line
              dataKey={line.dataName}
              name={line.friendlyName}
              stroke={COLORS[index % COLORS.length]}
              strokeWidth={3}
              dot={{ r: 4 }}
              activeDot={{ r: 8 }}
              key={`line${index}${line.dataName}`}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default RiqChartsLine;
