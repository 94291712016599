import { useState, useEffect } from "react";
import Children from "react-children-utilities";
import Omnisearch from "../Input/Omnisearch";

const Table = ({
  header = [],
  data = [],
  quickFilter,
  resetQuickFilter = () => {},
}) => {
  const [omnisearch, setOmnisearch] = useState("");
  const dataOmnifiltered = data.filter((obj) =>
    Object.keys(obj).some((key) =>
      Children.onlyText(obj[key])
        .replace(/\s+/g, " ")
        .toLowerCase()
        .includes(omnisearch.replace(/\s+/g, " ").toLowerCase())
    )
  );

  // change omnisearch on quickfilter update
  useEffect(() => {
    quickFilter ? setOmnisearch(quickFilter) : setOmnisearch("");
  }, [quickFilter]);

  return (
    <>
      <Omnisearch
        styleClass="mb-3"
        id="omnisearch"
        placeholder="Cerca"
        value={omnisearch}
        onChange={(e) => {
          setOmnisearch(e.target.value);
        }}
        onClick={() => {
          resetQuickFilter(null);
        }}
      />

      <div className="table-responsive">
        <table className="table">
          {header.length > 0 && (
            <thead>
              <tr>
                {header.map((title, index) => (
                  <th scope="col" key={index + title + "tableheader"}>
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          <tbody>
            {dataOmnifiltered.map((row, rowIndex) => {
              return (
                <tr key={rowIndex + "tablerow"}>
                  {Object.keys(row).map((key, childIndex) => {
                    return (
                      <td
                        key={rowIndex + childIndex + "tablecell"}
                        className="align-middle py-3"
                      >
                        {row[key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
