import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FetchService from "../../services/FetchService";
import Input from "../../components/Input/Input";
import Loadable from "../../components/Loader/Loadable";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import { formatErrorForAlert } from "../../services/UtilsService";

const ResetPassword = ({ setIsResetPassword }) => {
  const [alert, setAlert] = useState({});
  const [loginEmail, setLoginEmail] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const btnLogin = useRef();
  const navigate = useNavigate();

  const handleResetPassword = () => {
    setIsLoadingButton(true);

    let body = {
      email: loginEmail,
    };
    FetchService.post("/api/auth/forgot-password", body)
      .then(() => {
        setAlert({
          message: "Controlla la tua mail per una nuova password temporanea",
          messageType: "success",
        });
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  // listener for enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        btnLogin.current.click();
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <Alert
        styleClass={""}
        alertText={alert && alert.message}
        styleColor={alert && alert.messageType}
        onClick={setAlert}
        paddingLeft={0}
      />

      <div className="text-center container-login">
        <img
          src={process.env.PUBLIC_URL + "/imgs/logo.svg"}
          className="logo-login mb-4"
          alt="roomsiq logo"
        />

        <Input
          styleClass="mb-2"
          type="email"
          id="loginEmail"
          placeholder="name@example.com"
          value={loginEmail}
          onChange={(e) => {
            setLoginEmail(e.target.value);
          }}
          label={"Email"}
        />

        <div className="text-end mb-4">
          <div
            className="cursor-pointer forgot-pw"
            onClick={() => {
              setIsResetPassword(false);
            }}
          >
            Torna alla schermata di login
          </div>
        </div>
      </div>

      <div className="container-btn">
        <Loadable isLoading={isLoadingButton}>
          <Button
            text="Invia mail di reset"
            onClick={() => {
              handleResetPassword();
            }}
            innerRef={btnLogin}
          />
        </Loadable>
      </div>
    </>
  );
};

export default ResetPassword;
