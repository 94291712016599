const Input = ({
  styleClass = "",
  styleClassInput,
  type = "text",
  id = `input`,
  placeholder = "Input",
  label = "Input",
  value,
  onChange = () => {},
  onClick = () => {},
}) => {
  return (
    <div className={"form-floating " + styleClass}>
      <input
        type={type}
        className={"form-control " + styleClassInput}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onClick={onClick}
      />
      <label htmlFor="{id}">{label}</label>
    </div>
  );
};

export default Input;
