import Title from "../Title/Title";
import "./Navbar.css";

const Navbar = ({ title, menu }) => {
  return (
    <div className="container-navbar sticky py-3 mb-4">
      <div className="container d-flex justify-content-between align-items-center">
        <div>
          <Title styleClass="mb-0">{title}</Title>
          {/* <div className="font-grey">Last update: 10/10/23</div> */}
        </div>
        <div className="d-none d-sm-flex justify-content-end align-items-center">
          {menu &&
            menu.map((el, index) => (
              <div
                className="responsive-ms cursor-pointer navbar-link"
                key={el + index}
              >
                <a href={"#" + el}>
                  <b>{el}</b>
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
