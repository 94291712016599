import Loader from "../Loader/Loader";
import _variables from "../../styles/_variables";
import "./Refreshing.css";

const Refreshing = ({ conditions = [false], marginLeft = true }) => {
  {
    return conditions.some((cond) => cond) ? (
      <div
        className={
          "refreshing-container px-3 py-2 " +
          (marginLeft ? "ml-expanded" : "ml-not-expanded")
        }
      >
        <div className="mb-1">
          <strong>Loading data</strong>
        </div>
        <Loader color={_variables.yellow} type={"BarLoader"} />
      </div>
    ) : (
      <></>
    );
  }
};

export default Refreshing;
