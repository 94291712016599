import { useEffect, useRef } from "react";
import Calendar from "react-calendar";
import InputToggle from "../Input/InputToggle";
import "react-calendar/dist/Calendar.css";
import "./CalendarModal.css";

// https://www.npmjs.com/package/react-calendar
const CalendarModal = ({
  selectedDate,
  handleSetSelectedDate,
  calendarMode,
  switchCalendarMode,
  isChecked,
  setShowCalendar,
}) => {
  const modalRef = useRef();

  // check outside click
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // handle closing if click outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  return (
    <div className="container-calendar" ref={modalRef}>
      <div className="py-1 px-2 d-flex justify-content-end">
        <div className="me-1">Modalità mese</div>
        <InputToggle
          checked={isChecked}
          onChange={() => {
            switchCalendarMode();
          }}
        />
      </div>
      <Calendar
        onClickYear={handleSetSelectedDate}
        onClickMonth={handleSetSelectedDate}
        value={selectedDate}
        view={calendarMode}
      />
    </div>
  );
};

export default CalendarModal;
