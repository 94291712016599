import { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "../../services/AccountService";
import {
  formatDateSelected,
  computePreviousDate,
} from "../../services/UtilsService";
import SidebarService from "../../services/SidebarService";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import CalendarModal from "../CalendarModal/CalendarModal";
import "./Sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showCalendar, setShowCalendar] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const {
    isSuperadmin,
    selectedDate,
    setSelectedDate,
    displayDate,
    setDisplayDate,
    calendarMode,
    setCalendarMode,
    setPreviousDate,
    logout,
  } = useContext(AccountContext);

  useEffect(() => {
    if (!selectedDate) {
      handleSetSelectedDate(new Date());
    }
  }, []);

  const switchCalendarMode = () => {
    let newCM =
      calendarMode === "decade"
        ? "year"
        : calendarMode === "year"
        ? "decade"
        : "";

    setPreviousDate(computePreviousDate(newCM, selectedDate));
    setDisplayDate(formatDateSelected(newCM, selectedDate));

    setIsChecked(!isChecked);
    setCalendarMode(newCM);
  };

  const handleSetSelectedDate = (date) => {
    setSelectedDate(date);
    setPreviousDate(computePreviousDate(calendarMode, date));
    setDisplayDate(formatDateSelected(calendarMode, date));
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className="container-sidebar d-flex flex-column flex-shrink-0 justify-content-between px-2 py-3 sticky">
      <div>
        <Link to={"/"}>
          <img
            src={process.env.PUBLIC_URL + "/imgs/logo_crop_square.png"}
            className="w-100"
            alt="Sidebar logo"
          />
        </Link>

        {location.pathname === "/" || location.pathname === "/my" ? (
          <div className="calendar-container d-flex justify-content-center mt-3 position-relative cursor-pointer">
            <div
              className="w-100 h-100 text-center"
              onClick={() => {
                setShowCalendar(!showCalendar);
              }}
            >
              {displayDate ? displayDate : "-"}
            </div>

            {showCalendar && (
              <CalendarModal
                selectedDate={selectedDate}
                handleSetSelectedDate={handleSetSelectedDate}
                calendarMode={calendarMode}
                switchCalendarMode={switchCalendarMode}
                isChecked={isChecked}
                setShowCalendar={setShowCalendar}
              />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>

      <div>
        {SidebarService.map((navItem, navIndex) => {
          return navItem.auth.includes(isSuperadmin ? "admin" : "user") ? (
            <TooltipWrapper
              content={navItem.title}
              key={navIndex + navItem.title + "sidebaricon"}
            >
              <div className="mb-3">
                <Link
                  to={navItem.path}
                  className={
                    "nav-link " +
                    (location.pathname.includes(navItem.category) ||
                    (location.pathname === "/" && navItem.category === "my") ||
                    (location.pathname === "/" &&
                      navItem.category === "add-user")
                      ? "active"
                      : "")
                  }
                >
                  <img src={navItem.icon} className="sidebar-icon"></img>
                </Link>
              </div>
            </TooltipWrapper>
          ) : (
            <div
              className="d-none"
              key={navIndex + navItem.title + "sidebariconhide"}
            ></div>
          );
        })}
      </div>

      <div className="" onClick={handleLogout}>
        <img
          src={process.env.PUBLIC_URL + "/imgs/logout.png"}
          className="sidebar-icon logout cursor-pointer"
        ></img>
      </div>
    </div>
  );
};

export default Sidebar;
