import { ReactFitty } from "react-fitty";
import "./KpiBlock.css";

const KpiBlock = ({
  valueLeft = 0,
  textLeft = "TEXT",
  valueRight = 0,
  textRight = "TEXT",
  kpiLeft = true,
  kpiRight = true,
}) => {
  return (
    <>
      <div className="d-block d-lg-flex justify-content-center align-items-center my-5">
        {kpiLeft && (
          <div className="kpi left">
            <div className="kpi-text-container-left">
              <h5 className="kpi-value">
                <ReactFitty maxSize={96}>{valueLeft}</ReactFitty>
              </h5>
              <h6 className="kpi-subtitle">{textLeft}</h6>
            </div>
          </div>
        )}

        {kpiRight && (
          <div className="kpi right">
            <div className="kpi-text-container-right">
              <h5 className="kpi-value">
                <ReactFitty maxSize={96}>{valueRight}</ReactFitty>
              </h5>
              <h6 className="kpi-subtitle">{textRight}</h6>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default KpiBlock;
