import LayoutFullSideNav from "../../components/Layout/LayoutFullSideNav";
import LayoutSection from "../../components/Layout/LayoutSection";
import SmallTitleGraphic from "../../components/Title/SmallTitleGraphic";
import RiqChartsLine from "../../components/RiqCharts/RiqChartsLine";
import KpiBlock from "../../components/Kpi/KpiBlock";
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";

const RateShopper = () => {
  const title = "Rate shopper";
  const menu = ["COMPSET RATE"];

  return (
    <LayoutFullSideNav title={title} menu={menu}>
      {/* TODO: da togliere se esistono questi dati */}
      <WorkInProgress
        logo={process.env.PUBLIC_URL + "/imgs/lighthouse.jpg"}
        section={title}
        provider={"Lighthouse (formerly OTA Insight)"}
      />

      <LayoutSection>
        <SmallTitleGraphic content={menu[0]} shapeType={1} />
        <RiqChartsLine
          data={[
            {
              month: "JAN",
              HotelA: 160.6,
              HotelB: 217.0,
              HotelC: 110.0,
              HotelD: 97.5,
            },
            {
              month: "FEB",
              HotelA: 133.5,
              HotelB: 222.3,
              HotelC: 150.0,
              HotelD: 96.6,
            },
            {
              month: "MAR",
              HotelA: 249.1,
              HotelB: 292.9,
              HotelC: 150.0,
              HotelD: 96.6,
            },
            {
              month: "APR",
              HotelA: 230.2,
              HotelB: 192.7,
              HotelC: 110.0,
              HotelD: 99.4,
            },
            {
              month: "MAY",
              HotelA: 151.5,
              HotelB: 217.1,
              HotelC: 110.0,
              HotelD: 99.4,
            },
            {
              month: "JUN",
              HotelA: 195.4,
              HotelB: 210.5,
              HotelC: 130.0,
              HotelD: 95.0,
            },
            {
              month: "JUL",
              HotelA: 126.0,
              HotelB: 194.7,
              HotelC: 195.0,
              HotelD: 89.2,
            },
            {
              month: "AUG",
              HotelA: 244.6,
              HotelB: 200.6,
              HotelC: 195.0,
              HotelD: 99.4,
            },
            {
              month: "SEP",
              HotelA: 131.2,
              HotelB: 210.5,
              HotelC: 195.0,
              HotelD: 99.4,
            },
            {
              month: "OCT",
              HotelA: 110.2,
              HotelB: 207.3,
              HotelC: 110.0,
              HotelD: 84.2,
            },
            {
              month: "NOV",
              HotelA: 156.4,
              HotelB: 231.5,
              HotelC: 110.0,
              HotelD: 72.3,
            },
            {
              month: "DEC",
              HotelA: 131.2,
              HotelB: 227.3,
              HotelC: 110.0,
              HotelD: 85.0,
            },
          ]}
          dataGuide={{
            x: "month",
            linesData: [
              { dataName: "HotelA", friendlyName: "Hotel A" },
              { dataName: "HotelB", friendlyName: "Hotel B" },
              { dataName: "HotelC", friendlyName: "Hotel C" },
              { dataName: "HotelD", friendlyName: "Hotel D" },
            ],
          }}
        />
        <KpiBlock
          valueLeft={"154.9"}
          textLeft="MEDIA RATE"
          valueRight={"292.9"}
          textRight="MEDIAN RATE"
        />
      </LayoutSection>
    </LayoutFullSideNav>
  );
};

export default RateShopper;
