import { useState } from "react";
import Input from "./Input";

const InputPassword = ({
  styleClass = "",
  id = `inputpw`,
  placeholder = "Input",
  label = "Input",
  value,
  onChange = () => {},
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordShown = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className={"input-group " + styleClass}>
      <Input
        type={passwordShown ? "text" : "password"}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        label={label}
      />
      <span
        className="input-group-text"
        onClick={togglePasswordShown}
        role="button"
      >
        <i
          className={
            "bi " + (passwordShown ? "bi-eye-slash-fill" : "bi-eye-fill")
          }
        ></i>
      </span>
    </div>
  );
};

export default InputPassword;
