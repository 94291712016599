const InputToggle = ({
  styleClass = "",
  id = `inputtoggle`,
  onChange = () => {},
  checked = false,
}) => {
  return (
    <div className={"form-check form-switch " + styleClass}>
      <input
        className="form-check-input cursor-pointer"
        type="checkbox"
        id={id}
        onChange={onChange}
        checked={checked}
      />
    </div>
  );
};

export default InputToggle;
