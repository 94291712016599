import { useState, useEffect, useRef } from "react";
import { formatErrorForAlert } from "../../services/UtilsService";
import { useNavigate } from "react-router-dom";
import FetchService from "../../services/FetchService";
import InputPassword from "../../components/Input/InputPassword";
import Loadable from "../../components/Loader/Loadable";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";

const FirstLogin = ({ setIsFirstLogin, loginEmail, loginPassword }) => {
  const btnLogin = useRef();
  const [isRepeated, setIsRepeated] = useState(false);
  const [isLong, setIsLong] = useState(false);
  const [isLowercase, setIsLowercase] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);
  const [isNotIdentical, setIsNotIdentical] = useState(false);
  const [isNotConsecutive, setIsNotConsecutive] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [alert, setAlert] = useState({});
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const navigate = useNavigate();

  // listener for enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        btnLogin.current.click();
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  // check password conditions while typing
  useEffect(() => {
    setIsRepeated(newPassword === repeatPassword && newPassword !== "");
    setIsLong(newPassword.length >= 12);
    setIsLowercase(newPassword.match(/[a-z]+/));
    setIsUppercase(newPassword.match(/[A-Z]+/));
    setIsNumber(newPassword.match(/[0-9]+/));
    setIsSpecial(newPassword.match(/[!@#$%^&*]+/));
    setIsNotIdentical(!newPassword.match(/(.)\1\1\1/) && newPassword !== "");
    setIsNotConsecutive(
      !newPassword.match(
        /(abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz|0123|1234|2345|3456|4567|5678|6789)/
      ) && newPassword !== ""
    );
  }, [newPassword, repeatPassword]);

  const handleChangePassword = () => {
    setIsLoadingButton(true);

    let body = {
      email: loginEmail,
      old_password: loginPassword,
      new_password: newPassword,
    };
    FetchService.post("/api/auth/change-password", body)
      .then(() => {
        setIsFirstLogin(false);
        navigate("/login");
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
        setIsLoadingButton(false);
      });
  };

  return (
    <>
      <Alert
        styleClass={""}
        alertText={alert && alert.message}
        styleColor={alert && alert.messageType}
        onClick={setAlert}
        paddingLeft={0}
      />

      <div className="text-center container-login">
        <img
          src={process.env.PUBLIC_URL + "/imgs/logo.svg"}
          className="logo-login mb-4"
          alt="roomsiq logo"
        />

        <InputPassword
          styleClass="mb-2"
          type="password"
          id="newPassword"
          placeholder="new password"
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          label="New Password"
        />
        <InputPassword
          styleClass="mb-2"
          type="password"
          id="repeatPassword"
          placeholder="repeat password"
          value={repeatPassword}
          onChange={(e) => {
            setRepeatPassword(e.target.value);
          }}
          label="Repeat Password"
        />
      </div>

      <hr className="my-3" />
      <div className="text-start">
        <ul className="condition-list">
          <li className={isRepeated ? "active" : ""}>
            Password repeated correctly
          </li>
          <li className={isLong ? "active" : ""}>12 characters or longer</li>
          <li className={isLowercase ? "active" : ""}>
            At least 1 lowercase character
          </li>
          <li className={isUppercase ? "active" : ""}>
            At least 1 uppercase character
          </li>
          <li className={isNumber ? "active" : ""}>At least 1 number</li>
          <li className={isSpecial ? "active" : ""}>
            At least 1 special character (e.g. !@#$%^&*){" "}
          </li>
          <li className={isNotIdentical ? "active" : ""}>
            No more than 3 identical characters in a row (e.g. 'aaaa', '1111'){" "}
          </li>
          <li className={isNotConsecutive ? "active" : ""}>
            No more than 3 consecutive characters in a row (e.g. 'abcd', '1234')
          </li>
        </ul>
      </div>
      <hr className="my-3" />

      <div className="container-btn mb-5">
        <Loadable isLoading={isLoadingButton}>
          <Button
            text="Set new password"
            onClick={() => {
              handleChangePassword();
            }}
            innerRef={btnLogin}
            disabled={
              !(
                isRepeated &&
                isLong &&
                isLowercase &&
                isUppercase &&
                isNumber &&
                isSpecial &&
                isNotIdentical &&
                isNotConsecutive
              )
            }
          />
        </Loadable>
      </div>
    </>
  );
};

export default FirstLogin;
