const _variables = {
  black: "#000000",
  white: "#ffffff",
  yellow: "#f8e905",
  grey: "#cccccc",
};

const _variablesChart = {
  black: "#000000",
  yellow: "#f8e905",
  complementaryBlue: "#5005F7",
  complementaryViolet: "#E900FF",
};

export { _variablesChart };
export default _variables;
