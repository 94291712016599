import { useState, useEffect } from "react";
import FetchService from "../../services/FetchService";
import { formatErrorForAlert, titleCase } from "../../services/UtilsService";
import LayoutFullSideNav from "../../components/Layout/LayoutFullSideNav";
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import Loadable from "../../components/Loader/Loadable";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import Select from "../../components/Select/Select";
import Alert from "../../components/Alert/Alert";

const AddUser = () => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [alert, setAlert] = useState({});
  const [users, setUsers] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [usersFormatted, setUsersFormatted] = useState([]);
  const [hotelsFormatted, setHotelsFormatted] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  // get all data on load
  useEffect(() => {
    getUsers();
    getHotels();
  }, []);

  // format users for table
  useEffect(() => {
    const formatData = () => {
      let tempFormatted = [];
      users.forEach((el) => {
        if (el.active && el.hotel_name) {
          tempFormatted = [
            ...tempFormatted,
            {
              email: el.email,
              hotel: titleCase(el.hotel_name) || "-",
              button: (
                <div className="text-end">
                  <Button
                    style={{ minWidth: "0px" }}
                    text={<i className="bi bi-trash-fill"></i>}
                    onClick={() => {
                      deleteUser(el.id);
                    }}
                  />
                </div>
              ),
            },
          ];
        }
      });
      setUsersFormatted(tempFormatted);
    };

    formatData();
  }, [users]);

  // format hotels for select
  useEffect(() => {
    const formatData = () => {
      let tempFormatted = [];
      hotels.forEach((el) => {
        if (el.active) {
          tempFormatted = [
            ...tempFormatted,
            {
              value: el.customer,
              text: titleCase(el.customer),
            },
          ];
        }
      });
      setHotelsFormatted(tempFormatted);
    };

    formatData();
  }, [hotels]);

  const getUsers = () => {
    FetchService.get("/api/superadmin/users")
      .then((res) => {
        setUsers(
          res.data.sort((a, b) => {
            const hotelA = a.hotel_name || "";
            const hotelB = b.hotel_name || "";
            return hotelA.localeCompare(hotelB);
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getHotels = () => {
    FetchService.get("/api/superadmin/hotels")
      .then((res) => {
        setHotels(
          res.data.sort((a, b) => {
            const hotelA = a.customer || "";
            const hotelB = b.customer || "";
            return hotelA.localeCompare(hotelB);
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const addUser = () => {
    setIsLoadingButton(true);

    let body = {
      email: inviteEmail,
      hotel_name: selectedHotel,
      first_login: true,
      is_superadmin: false,
    };

    FetchService.post("/api/superadmin/users", body)
      .then(() => {
        setAlert({
          message: "Utente aggiunto con successo",
          messageType: "success",
        });
        getUsers();
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  const deleteUser = (userId) => {
    if (
      window.confirm(
        `Sei sicuro di voler cancellare ${
          users.find((u) => u.id === userId).email
        }?`
      )
    ) {
      FetchService.patch(`/api/superadmin/users/${userId}/status`, {
        active: false,
      })
        .then(() => {
          setAlert({
            message: "Utente cancellato con successo",
            messageType: "success",
          });
          setUsers(users.filter((u) => u.id !== userId));
        })
        .catch((err) => {
          setAlert({ message: formatErrorForAlert(err), messageType: "error" });
        });
    }
  };

  const handleSelectHotel = (e) => {
    setSelectedHotel(e);
  };

  return (
    <LayoutFullSideNav title={"Gestione utenti"} lastUpdate={false}>
      <Alert
        styleClass={""}
        alertText={alert && alert.message}
        styleColor={alert && alert.messageType}
        onClick={setAlert}
      />

      <Title>Aggiungi Utente</Title>

      <div className="row mb-5">
        <div className="col-12 col-lg-6">
          <Input
            styleClass="mb-2 mb-lg-0"
            type="email"
            id="inviteEmail"
            placeholder="name@example.com"
            value={inviteEmail}
            onChange={(e) => {
              setInviteEmail(e.target.value);
            }}
            label={"Email"}
          />
        </div>
        <div className="col-6 col-lg-4">
          <Select
            options={hotelsFormatted}
            onChange={handleSelectHotel}
            placeholder="Seleziona hotel"
            styleClass="w-100"
          />
        </div>
        <div className="col-lg-2 mt-2 mt-lg-0 text-end align-self-center">
          <Loadable isLoading={isLoadingButton}>
            <Button
              text="Aggiungi"
              onClick={() => {
                addUser();
              }}
            />
          </Loadable>
        </div>
      </div>

      <Title>Utenti Attivi</Title>
      <Table data={usersFormatted} header={["Email", "Hotel", ""]} />
    </LayoutFullSideNav>
  );
};

export default AddUser;
