const SidebarService = [
  {
    title: "Gestione utenti",
    path: "/add-user",
    category: "add-user",
    auth: ["admin"],
    icon: process.env.PUBLIC_URL + "/imgs/add-user.png",
  },
  {
    title: "I miei indicatori",
    path: "/my",
    category: "my",
    auth: ["user"],
    icon: process.env.PUBLIC_URL + "/imgs/my.png",
  },
  {
    title: "Compset",
    path: "/compset",
    category: "compset",
    auth: ["user"],
    icon: process.env.PUBLIC_URL + "/imgs/compset.png",
  },
  {
    title: "Mercato",
    path: "/market",
    category: "market",
    auth: ["user"],
    icon: process.env.PUBLIC_URL + "/imgs/market.png",
  },
  {
    title: "Rate shopper",
    path: "/rate-shopper",
    category: "rate-shopper",
    auth: ["user"],
    icon: process.env.PUBLIC_URL + "/imgs/rate-shopper.png",
  },
];

export default SidebarService;
