import { useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import "./NoDataAvailable.css";

const NoDataAvailable = ({ isLoading = false }) => {
  /* const { displayDate } = useContext(AccountContext); */

  return (
    <div className="nodata-container d-flex justify-content-center align-items-center flex-column">
      {isLoading ? (
        <>
          <div className="font-grey">
            <h4>
              <i>Loading...</i>
            </h4>
          </div>
        </>
      ) : (
        <>
          <div className="font-grey">
            <h4>
              <i>Dati non disponibili</i>
            </h4>
          </div>

          {/* <div className="font-grey ">
            <h2>
              <i>{displayDate}</i>
            </h2>
          </div> */}
        </>
      )}
    </div>
  );
};

export default NoDataAvailable;
