import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AccountService } from "./services/AccountService";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import App from "./App";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AccountService>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </AccountService>
);
